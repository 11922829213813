import React, { useState, useRef } from 'react';

function Collapse({headline, content}) {
    const [isActive, setIsActive] = useState(false);
    const contentRef = useRef(null);

    const toggleCollapse = () => {
        setIsActive((prev) => !prev);
    };

    return (
        <>
            <div
                type="button"
                className={`collapsible ${isActive ? 'collapsible-active' : ''}`}
                onClick={toggleCollapse}
            >{headline}</div>
            <div
                ref={contentRef}
                className="collapsible-content"
                style={{
                    maxHeight: isActive ? `${contentRef.current?.scrollHeight}px` : '0px',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease-out',
                }}
            >
                {content}
            </div>
        </>
    );
}

export default Collapse;