import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Collapse from "./Collapse";

function Anpaddeln_Form() {
  const [state, handleSubmit] = useForm("mvggvrlv");
  if (state.succeeded) {
    return (
      <div className="form__container">
        <p>
          <b>Vielen Dank für die Anmeldung!</b>
          <br />
          <br />
          Wir melden uns ab dem 28.02.2025 mit weiteren Informationen. Wir
          freuen uns auf euch. Viele Grüße vom Caipi Orga Team
        </p>
      </div>
    );
  }
  return (
    <div className="form__container">
      <form onSubmit={handleSubmit}>
        <div className="fullWidth">
          <h3>Ahoi liebe Paddelfreunde und alle, die es noch werden wollen!</h3>
          <p>
            Unter dem Motto “LAND IN SICHT!” starten wir gemeinsam in die
            Outdoor Drachenboot Saison. Euch erwarten spannende
            Herausforderungen - also schnappt euch die Paddel, packt das
            Schweizer Taschenmesser ein, nehmt das Fernrohr zur Hand. Mit viel
            Spaß paddeln wir bekannten und unbekannten Ufern entgegen - bereit
            für neue Abenteuer. Leinen Los, wir freuen uns auf eure Anmeldung.
          </p>
          <br />
          <p>
            <b>Wann:</b> Samstag, den 05.04.2025
            <br />
            <b>Wo:</b> Isekai 12, Hamburg (WSAP Steg)
            <br />
            <b>Startgeld:</b> 8 € pro Kopf <br />
            <b>Teams:</b> Mindestens 3 Teams, bei weniger Anmeldungen behalten
            wir uns vor, die Veranstaltung abzusagen.
          </p>
          <br />
          <p>
            <b>Bootsbesetzung:</b> Wir empfehlen euch, mit 14 bis 18 Paddlern an
            den Start zu gehen. Schließlich wollt ihr bequem und entspannt die
            Stationen abfahren. Dafür braucht ihr auch noch Platz im Boot für
            Verpflegung und Bestechungsmaterial. Euer Boot benötigt auf jeden
            Fall eine Trommel, diese muss aber nicht immer besetzt sein. Wie ihr
            das Boot besetzt, ist aber letzten Endes eure Entscheidung!
          </p>
          <br />
          <p>
            <b>Anmeldeformular:</b> Bitte meldet Euch als gesamtes Team an. Ihr
            möchtet mit Eurem Team gerne dabei sein, seid aber nicht komplett?
            In der Anmeldung werden wir abfragen, wie viel Paddler ihr seid, ob
            ihr bereits ein Boot, eine Person an der Trommel oder am Steuer
            habt. Basierend auf euren Angaben werden wir bei Bedarf
            Renngemeinschaften zusammenstellen.
          </p>
          <br />
          <p>
            <b>Anmeldung: Bis zum 28.02.2025</b>
          </p>
          <br />
          <p>
            Bei Fragen schreibt uns eine Mail an:{" "}
            <a href="mailto:anpaddeln@caipis-drachenboot.de">
              anpaddeln@caipis-drachenboot.de
            </a>
            <br />
            Wir freuen uns auf Eure zahlreichen Anmeldungen!
            <br />
            <br />
            Caipi Grüße vom <br />
            Anpaddeln Orga Team
          </p>
          <br />
          <br />
        </div>
        <div className="fullWidth">
          <h3>Allgemeine Infos</h3>
        </div>
        <div>
          <label htmlFor="Vorname_Nachname">Vor- und Nachname *</label>
          <br />
          <input
            id="Vorname_Nachname"
            type="text"
            name="Vorname_Nachname"
            required
          />
        </div>
        <div>
          <label htmlFor="PLZ_Ort">PLZ und Ort *</label>
          <br />
          <input id="PLZ_Ort" type="text" name="PLZ_Ort" required />
        </div>
        <div>
          <label htmlFor="Telefon">Telefon *</label>
          <br />
          <input id="Telefon" type="telephone" name="Telefon" required />
        </div>
        <div>
          <label htmlFor="Email">E-Mail-Addresse *</label>
          <br />
          <input id="Email" type="email" name="Email" required />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <br />
        <div className="fullWidth">
          <h3>Eure Mannschaft</h3>

          <p>
            Am Veranstaltungstag ist eine unterschriebene Liste der
            teilnehmenden Paddler beim Organisationsteam abzugeben. Ihr erhaltet
            den Vordruck zusammen mit der Anmeldebestätigung per E-Mail.
          </p>
        </div>
        <div>
          <label htmlFor="Mannschaftsname">Mannschaftsname *</label>
          <br />
          <input
            id="Mannschaftsname"
            type="text"
            name="Mannschaftsname"
            required
          />
        </div>
        <div>
          <label htmlFor="Vereinname">Verein *</label>
          <br />
          <input id="Vereinname" type="text" name="Vereinname" required />
        </div>
        <div>
          <label htmlFor="Teilnehmer">Anzahl der Teilnehmer *</label>
          <br />
          <input
            type="number"
            id="Teilnehmer"
            name="Teilnehmer"
            min="1"
            max="22"
            required
          />
        </div>
        <div>
          <label htmlFor="Andere_Paddler">Platz für andere Paddler *</label>
          <br />
          <input
            type="number"
            id="Andere_Paddler"
            name="Andere_Paddler"
            min="0"
            max="20"
            required
          />
        </div>
        <div>
          <p>Wir haben eine Person, die steuert *</p>
          <label>
            <input type="radio" name="Steuerer" value="Ja" required /> Ja
          </label>
          <label>
            <input type="radio" name="Steuerer" value="Nein" required /> Nein
          </label>
        </div>
        <div>
          <p>Wir haben eine Person, die trommelt *</p>
          <label>
            <input type="radio" name="Trommler" value="Ja" required /> Ja
          </label>
          <label>
            <input type="radio" name="Trommler" value="Nein" required /> Nein
          </label>
        </div>
        <div>
          <p>Wir haben ein Boot *</p>
          <label>
            <input type="radio" name="Boot" value="Ja" required /> Ja
          </label>
          <label>
            <input type="radio" name="Boot" value="Nein" required /> Nein
          </label>
        </div>
        <br />
        <br />
        <br />
        <div className="fullWidth">
          <p className="small">
            * Pflichtfelder, diese Felder müssen ausgefüllt werden.
          </p>
        </div>
        <br />
        <br />
        <div className="fullWidth">
          <h3>Informationen</h3>
        </div>
        <div className="fullWidth">
          <p>
            Das Startgeld EUR 8,00 pro Person ist in einer Summe zu überweisen.
            Nach Ablauf der Anmeldefrist werden wir uns bei euch melden und euch
            sämtliche weitere Informationen sowie die Bankverbindung zukommen
            lassen.
          </p>
        </div>
        <div className="fullWidth">
          <Collapse
            headline="Teilnahmebedingungen und Sicherheitsbelehrung"
            content={
              <>
                <ol>
                  <li>
                    Teilnahmeberechtigt sind nur Teams, die ihre Anmeldung
                    abgegeben und nach einer Anmeldebestätigung das Startgeld (8
                    Euro pro Kopf) termingerecht bezahlt haben. Die Ausrichter
                    entscheiden als Veranstalter über die Teilnahme eines Teams.{" "}
                  </li>
                  <li>
                    Jedes Team benennt einen Teamcaptain (TC), der während des
                    Events Ansprechpartner für die Veranstalter und das
                    Organisationsteam ist. Der TC nimmt am TC-Meeting teil und
                    ist während des Events verantwortlich für die Sicherheit des
                    Teams an Land und auf dem Wasser.{" "}
                  </li>
                  <li>
                    Der TC verpflichtet sich, diese Teilnahmebedingungen und
                    Sicherheitsbelehrung jedem Teammitglied vor der
                    Veranstaltung bekannt zu machen. Der TC bestätigt mit seiner
                    Unterschrift, dass alle Teammitglieder diese
                    "Teilnahmebedingungen und Sicherheitsbelehrung" kennen.{" "}
                  </li>
                  <li>
                    Alle Teilnehmenden nehmen an der Veranstaltung auf eigene
                    Gefahr teil.{" "}
                  </li>
                  <li>
                    Alle Teilnehmenden erklären, dass sie 50 Meter in der
                    Kleidung, die sie während der Rennen im Boot tragen, sicher
                    schwimmen können.{" "}
                  </li>
                  <li>
                    Aus Rücksicht auf alle Teilnehmenden ist es
                    selbstverständlich, andere Teilnehmende nicht einer
                    gesundheitlichen Gefährdung auszusetzen, sondern in diesem
                    Fall auf eine Teilnahme zu verzichten.
                  </li>
                  <li>
                    Boote und Paddel werden nicht gestellt und sind von den
                    Teams selbst zu organisieren. Für eventuell entstehende
                    Schäden (Personen / Material) wird keine Haftung übernommen.
                    Jedes Team ist selbst für die Sicherheit zuständig. Bei der
                    Organisation eines Bootes kann der Veranstalter auf Anfrage
                    ggf. unterstützen.
                  </li>
                  <li>
                    Teams, die nicht bzw. nicht pünktlich zum Start erscheinen,
                    können vom Rennen ausgeschlossen werden. Eine Rückzahlung
                    des Startgeldes ist jedoch ausgeschlossen.{" "}
                  </li>
                  <li>
                    Den Anweisungen des Organisationsteams, sowie deren
                    Beauftragten und der Steuerleute ist zur eigenen Sicherheit
                    unbedingt und uneingeschränkt Folge zu leisten.{" "}
                  </li>
                  <li>
                    Verhalten, das die übrigen Teilnehmenden gefährdet,
                    Unsportlichkeit sowie Alkoholisierung können zum Ausschluss
                    bzw. zur Disqualifikation des betreffenden Teams führen.
                    Gleiches gilt bei Verstößen jeglicher Art gegen die
                    Teilnahmebedingungen.{" "}
                  </li>
                  <li>
                    Bei minderjährigen Teilnehmenden ist ein schriftliches
                    Einverständnis des Erziehungsberechtigten zur Teilnahme
                    einzuholen. Der TC bestätigt mit seiner Unterschrift auf der
                    Anmeldung, dass nur Teilnehmende mit entsprechender
                    Zustimmungserklärung starten.{" "}
                  </li>
                  <li>
                    Für minderjährige Teilnehmende besteht Schwimmwestenpflicht.{" "}
                  </li>
                  <li>
                    Der Veranstalter, der Ausrichter, die Sponsoren, freiwillige
                    Helfende, Angestellte, Offizielle sowie Personen, die mit
                    der Durchführung der Veranstaltung beauftragt sind, werden
                    mit der Unterschrift der verbindlichen Anmeldung und der
                    Sicherheitsbelehrung von jeglicher Haftung befreit. Diese
                    Haftungsbefreiung gilt auch für abhanden gekommene
                    Bekleidungs- und Ausrüstungsgegenstände sowie Wertsachen der
                    Teilnehmenden.{" "}
                  </li>
                  <li>
                    Die Rennleitung entscheidet unwiderruflich in allen Fragen
                    des Wettbewerbs.{" "}
                  </li>
                  <li>
                    Die Veranstaltung findet bei jeder Witterung statt. Bei
                    extremen Situationen (zum Beispiel Sturm, Gewitter etc.)
                    oder aufgrund besonderer Ereignisse (zum Beispiel Gefahr,
                    behördliche Anordnung etc.) kann die Rennleitung die
                    Veranstaltung verkürzen oder absagen. Eine Rückerstattung
                    des überwiesenen Startgeldes ist ausgeschlossen.{" "}
                  </li>
                  <li>
                    Das Team muss seinen Müll einsammeln, mitnehmen und einer
                    geeigneten Entsorgung zuführen.{" "}
                  </li>
                  <li>
                    Es ist möglich, dass verschiedene Medien über die
                    Veranstaltung berichten werden. Die Teilnehmenden erklären
                    sich mit der Verwertung von Film- und Fotoarbeiten im
                    Zusammenhang mit der Veranstaltung einverstanden.{" "}
                  </li>
                  Durch meine Unterschrift erkenne ich Punkt 1-17 der
                  Teilnahmebedingungen und Sicherheitsbelehrungen
                  uneingeschränkt an.
                </ol>
              </>
            }
          />
        </div>
        <div className="fullWidth">
          <Collapse
            headline="Weitere Hinweise"
            content={
              <>
                <p>
                  <b>Gibt es vor Ort Umkleidemöglichkeiten? </b>
                  <br />
                  Am WSAP-Bootshaus stehen Umkleideräume zur Verfügung. Beachtet
                  bitte, dass wir für Kleidung und Wertsachen keine Haftung
                  übernehmen.
                </p>
                <p>
                  <b>Gibt es vor Ort Verpflegungsmöglichkeiten?</b>
                  <br />
                  Wie in den vergangenen Jahren wird es wieder ein Buffet mit
                  Salaten, Brot, Obst etc. geben. Über eine Spende, die wieder
                  einem guten Zweck zukommen soll, freut sich das Orga-Team.
                  Eigene Verpflegung darf ebenfalls mitgebracht werden.
                </p>
                <p>
                  <b>Wo kann ich parken?</b>
                  <br />
                  Der Isekai ist eine Wohnstraße mit beiderseitigen - leider
                  kostenpflichtigen - Parkmöglichkeiten. Wir bitten daher darum,
                  möglichst mit öffentlichen Verkehrsmitteln anzureisen und/oder
                  Fahrgemeinschaften zu bilden.
                </p>
                <p>
                  <b>Haftungsbedingungen</b>
                  <br />
                  Der TC ist verantwortlich für das Team, die Einhaltung der
                  Rennregeln und der Sicherheitsbestimmungen. Er verpflichtet
                  sich, alle Teilnehmenden des Teams über die
                  Teilnahmebedingungen, den Haftungsausschluss und die
                  Sicherheitsbestimmungen zu informieren. Der Veranstalter und
                  die freiwillig Helfenden werden mit der Anmeldung von
                  jeglicher Haftung befreit.
                </p>
                <p>
                  <b>Wetterbedingungen</b>
                  <br />
                  Das Event findet bei jedem Wetter statt, es sei denn
                  Extremsituationen gefährden die Sicherheit der Teilnehmenden.
                  Hierüber entscheidet der Veranstalter.
                </p>
                <p>
                  <b>Mein Müll muss mit!</b>
                  <br />
                  Kanusport ist Natursport. Um die Natur zu erhalten, sollte ein
                  schonender Umgang mit Ressourcen selbstverständlich sein und
                  die Vermeidung von Müll gehört dazu. Bitte unterstützt uns und
                  achtet auf Euren Müll bzw. versucht diesen zu vermeiden.
                  Beachtet bitte, dass jedes Team für seinen Müll verantwortlich
                  ist und diesen am Ende der Veranstaltung vollständig mitnehmen
                  muss.
                </p>
                <p>Wir danken Euch und wünschen Euch viel Spaß und Erfolg!</p>
              </>
            }
          />
        </div>
        <div className="fullWidth">
          {/* <button type="submit" disabled={state.submitting}>
            <span>Abschicken</span>
            <div class="loader"></div>
          </button> */}

          <button
            type="submit"
            disabled={state.submitting}
            className={`submit-button ${state.submitting ? "loading" : ""}`}
          >
            <span>{state.submitting ? "Wird gesendet..." : "Abschicken"}</span>
            {state.submitting && <div className="loader"></div>}
          </button>

          <br />
          <p className="small">
            Mit dem Absenden dieses Formulars melde ich mich und meine
            Mannschaft verbindlich zum Anpaddeln 2025 an. Ich habe die
            Teilnahmebedingungen gelesen und erkenne diese uneingeschränkt an.
          </p>
        </div>
        <br />
        <br />
      </form>
    </div>
  );
}

export default Anpaddeln_Form;
