import React, { useState, useEffect } from "react";
import { getContentfulEntries } from "../contentful";
import GalleryOverlayed_New from "./GalleryOverlayed_New";

const PhotosEvents_New = () => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    getContentfulEntries().then((entries) => {
      let galleries = [];
      entries.items.map((entry) => {
        if (entry.sys.contentType.sys.id === "gallery") {
          galleries.push(entry);
        }
      });
      setEntries(galleries);
    });
  }, []);

  return (
    <section
      className="photosevents-section bg--white"
      id="caipis-photosevents"
    >
      <div>
        <h2>Events & Fotos</h2>
        <div className="flex--5">
          {entries.map((entry) => {
            return (
              <Card
                key={entry.sys.id}
                imageUrl={entry.fields.teaser.fields.file.url}
                eventName={entry.fields.name}
                galleryItems={entry.fields.images}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

const Card = ({ imageUrl, eventName, galleryItems }) => {
  const [isActive, setIsActive] = useState(false);

  // const handleClick = () => {
  //   setIsActive(!isActive);
  // };

  const handleClick = (state) => {
    setIsActive(state);
    if (state) {
      window.history.pushState(
        { gallery: eventName },
        "",
        `#gallery-${eventName}`
      );
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    const onPopState = (event) => {
      if (event.state && event.state.gallery === eventName) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, [eventName]);

  return (
    <>
      <a onClick={handleClick}>
        <div className={`card ${isActive ? "card--active" : ""}`}>
          <img src={imageUrl} alt="Eventbild" />
          <p>[&nbsp;{eventName}&nbsp;]</p>
        </div>
      </a>
      {isActive && (
        <GalleryOverlayed_New
          eventName={eventName}
          isActiveProp={isActive}
          galleryItems={galleryItems}
        />
      )}
    </>
  );
};

export default PhotosEvents_New;
