import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";

const Imprint = () => {
  const [isImprintActive, setIsImprintActive] = useState(false);
  const [isDataProtectionActive, setIsDataProtectionActive] = useState(false);

  // Öffnet das Overlay und fügt einen Verlaufseintrag hinzu
  const handleImprintClick = (state) => {
    setIsImprintActive(state);
    if (state) {
      window.history.pushState({ imprint: true }, "", "#imprint");
    } else {
      window.history.back(); // Gehe im Verlauf zurück
    }
  };

  const handleDataProtectionClick = (state) => {
    setIsDataProtectionActive(state);
    if (state) {
      window.history.pushState({ dataProtection: true }, "", "#dataprotection");
    } else {
      window.history.back(); // Gehe im Verlauf zurück
    }
  };

  // Überwachung des Back-Buttons
  useEffect(() => {
    const onPopState = (event) => {
      if (event.state && event.state.imprint) {
        setIsImprintActive(true);
      } else if (event.state && event.state.dataProtection) {
        setIsDataProtectionActive(true);
      } else {
        setIsImprintActive(false);
        setIsDataProtectionActive(false);
      }
    };

    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  return (
    <>
      <div className="imprint-section bg--blue">
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleImprintClick(true);
            }}
          >
            Impressum
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDataProtectionClick(true);
            }}
          >
            Datenschutz
          </a>
        </div>
        <div>
          <span>© 2025 WSAP Hamburg</span>
        </div>
      </div>

      {isImprintActive && (
        <div className={`imprintOverlay`}>
          <div className="imprintOverlay--header">
            <h4>Impressum</h4>
            <span>
              <MdClose
                onClick={(e) => {
                  e.preventDefault();
                  handleImprintClick(false);
                }}
              />
            </span>
          </div>
          <div className="imprintOverlay--body">
            <h3>Herausgeber</h3>
            Impressum/Anbieterkennzeichnung gemäß § 5 Digitale-Dienste-Gesetz
            (DDG), § 18 Medienstaatsvertrag (MStV)
            <br />
            <br />
            <b>Drachenboot-Team Caipiranhas der Abteilung Wassersport (WSAP)</b>
            <br />
            <br />
            Postanschrift:
            <br />
            Sportverein Polizei Hamburg von 1920 e.V.
            <br />
            Wassersport-Abteilung (WSAP)
            <br />
            Geschäftsstelle
            <br />
            Sternschanze 4
            <br />
            20357 Hamburg
            <br />
            Tel.: 040 / 411 72 794
            <br />
            Fax: 040 / 511 74 31
            <br />
            <a href="mailto:tc@caipis-drachenboot.de">
              tc@caipis-drachenboot.de
            </a>
            <br />
            <br />
            <b>Haftungshinweis</b>
            <br />
            Für die unter www.caipis-drachenboot.de verfügbaren Inhalte ist das
            Team der Caipiranhas verantwortlich. Trotz sorgfältiger inhaltlicher
            Kontrolle übernehmen wir keine Haftung für die Inhalte externer
            Links. Für den Inhalt der verlinkten Seiten sind ausschließlich
            deren Betreiber verantwortlich.
          </div>
        </div>
      )}

      {isDataProtectionActive && (
        <div className={`imprintOverlay`}>
          <div className="imprintOverlay--header">
            <h4>Datenschutz</h4>
            <span>
              <MdClose
                onClick={(e) => {
                  e.preventDefault();
                  handleDataProtectionClick(false);
                }}
              />
            </span>
          </div>
          <div className="imprintOverlay--body">
            <h3>1. Verantwortlicher</h3>
            <p>
              Verantwortlich für die Datenverarbeitung auf dieser Website ist:
            </p>
            <p>
              <strong>Team Caipiranhas</strong>
              <br />
              E-Mail:{" "}
              <a href="mailto:tc@caipis-drachenboot.de">
                tc@caipis-drachenboot.de
              </a>
            </p>

            <h3>2. Allgemeine Hinweise zur Datenverarbeitung</h3>
            <p>
              Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Die
              Verarbeitung personenbezogener Daten erfolgt gemäß den
              gesetzlichen Vorgaben der Datenschutz-Grundverordnung (DSGVO).
            </p>

            <h3>3. Erhebung und Speicherung personenbezogener Daten</h3>
            <b>a) Kontaktformular</b>
            <p>
              Wenn Sie uns über das Kontaktformular auf unserer Website
              kontaktieren, verarbeiten wir folgende Daten:
            </p>
            <ul>
              <li>Name</li>
              <li>E-Mail-Adresse</li>
            </ul>
            <p>
              Diese Daten werden ausschließlich zur Beantwortung Ihrer Anfrage
              genutzt.
            </p>

            <b>b) Formular für Event-Anmeldungen</b>
            <p>Für die Anmeldung zu Events erheben wir zusätzlich:</p>
            <ul>
              <li>Telefonnummer</li>
              <li>Postleitzahl</li>
              <li>Ort</li>
            </ul>
            <p>
              Die erhobenen Daten werden ausschließlich für die Organisation und
              Durchführung des jeweiligen Events genutzt.
            </p>

            <h3>4. Hosting und Datenverarbeitung</h3>
            <b>a) Hosting bei Firebase</b>
            <p>
              Unsere Website wird von Firebase gehostet, einem Dienst der Google
              LLC mit Serverstandorten innerhalb und außerhalb der EU. Google
              verpflichtet sich zur Einhaltung der DSGVO durch
              Standardvertragsklauseln.
            </p>

            <b>b) Nutzung von Formspree</b>
            <p>
              Für die Übermittlung von Formulardaten nutzen wir Formspree, einen
              Dienst mit Sitz in den USA. Die Daten können daher außerhalb der
              EU verarbeitet werden. Formspree stellt durch geeignete Maßnahmen
              sicher, dass die Verarbeitung der Daten gemäß der DSGVO erfolgt
              (z. B. Standardvertragsklauseln).
            </p>

            <h3>5. Ihre Rechte</h3>
            <p>
              Sie haben folgende Rechte hinsichtlich Ihrer personenbezogenen
              Daten:
            </p>
            <ul>
              <li>
                <strong>Auskunft:</strong> Sie können Auskunft über Ihre bei uns
                gespeicherten Daten verlangen.
              </li>
              <li>
                <strong>Berichtigung:</strong> Sie können die Berichtigung
                unrichtiger oder die Vervollständigung unvollständiger Daten
                verlangen.
              </li>
              <li>
                <strong>Löschung:</strong> Sie können die Löschung Ihrer Daten
                verlangen, sofern keine rechtliche Aufbewahrungspflicht besteht.
              </li>
              <li>
                <strong>Widerspruch:</strong> Sie können der Verarbeitung Ihrer
                Daten widersprechen.
              </li>
              <li>
                <strong>Datenübertragbarkeit:</strong> Sie haben das Recht, die
                Sie betreffenden Daten in einem gängigen, maschinenlesbaren
                Format zu erhalten.
              </li>
            </ul>
            <p>
              Wenden Sie sich hierzu an:{" "}
              <a href="mailto:tc@caipis-drachenboot.de">
                tc@caipis-drachenboot.de
              </a>
            </p>

            <h3>6. Internationale Datenübermittlung</h3>
            <p>
              Da Formspree in den USA ansässig ist, erfolgt eine Übermittlung
              Ihrer Daten in ein Drittland. Diese Übermittlung basiert auf den
              von der Europäischen Kommission genehmigten
              Standardvertragsklauseln.
            </p>

            <h3>7. Speicherdauer</h3>
            <p>
              Ihre personenbezogenen Daten werden nur so lange gespeichert, wie
              dies für die jeweiligen Zwecke erforderlich ist oder gesetzlich
              vorgeschrieben wird.
            </p>

            <h3>8. Änderungen der Datenschutzerklärung</h3>
            <p>
              Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um
              sie aktuellen rechtlichen Anforderungen oder Änderungen unserer
              Leistungen anzupassen.
            </p>
            <br />
            <br />
            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default Imprint;
