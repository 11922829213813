import React, { useState, useEffect } from "react";
import Header from "./Sections/Header";
import Start from "./Sections/Start";
import Teaser from "./Sections/Teaser";
import About from "./Sections/About";
import Training from "./Sections/Training";
// import PhotosEvents from "./Sections/PhotosEvents";
import PhotosEvents_New from "./Sections/PhotosEvents_New";
import Anpaddeln_Form from "./Sections/Anpaddeln_Form";
import Contact from "./Sections/Contact";
import Footer from "./Sections/Footer";
import Imprint from "./Sections/Imprint";

import { MdClose } from "react-icons/md";
import { getContentfulEntries } from "./contentful";

import "./Styles/style.scss";

function App() {
  const isMobile = window.innerWidth < 670;
  const [banner, setBanner] = useState([]);
  const [mBanner, setMBanner] = useState([]);
  const [bannerActive, setBannerActive] = useState([]);
  const [isAnpaddeln, setBannerIsAnpaddeln] = useState([]);

  const [isAnpaddelnActive, setIsAnpaddelnActive] = useState(false);

  const handleAnpaddelnLinkClick = (state) => {
    setIsAnpaddelnActive(state);
  };

  useEffect(() => {
    getContentfulEntries().then((entries) => {
      // let banner;
      entries.items.map((entry) => {
        if (entry.sys.contentType.sys.id === "banner") {
          setMBanner(entry.fields.banner_mobile.fields.file.url);
          setBanner(entry.fields.banner_image.fields.file.url);
          setBannerActive(entry.fields.active);
          setBannerIsAnpaddeln(entry.fields.isAnpaddeln);
        }
      });
    });
  }, []);

  // Überprüfen der URL bei der Initialisierung
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (window.location.href.includes("anpaddeln")) {
  //       setBannerIsAnpaddeln(true);
  //     }
  //   }, 1000);
  // }, []);

  return (
    <>
      <Header />
      <main>
        <Start />
        {banner &&
          (bannerActive === "true" || bannerActive === true) &&
          (!isMobile ? (
            <div style={{ backgroundColor: "#d4edfc", padding: "0px 0 0px 0" }}>
              <img
                src={banner}
                className="divider"
                style={{
                  display: "block",
                  width: "100%",
                  margin: "0 auto",
                }}
              />
            </div>
          ) : (
            <div style={{ backgroundColor: "#d4edfc", padding: "0px 0 0px 0" }}>
              <img
                src={mBanner}
                className="divider"
                style={{
                  display: "block",
                  width: "100%",
                  margin: "0 auto",
                }}
              />
            </div>
          ))}
        {banner &&
          (bannerActive === "true" || bannerActive === true) &&
          (isAnpaddeln === "true" || isAnpaddeln === true) && (
            <div>
              <a
                href=""
                className="open--form--link"
                onClick={(e) => {
                  e.preventDefault();
                  handleAnpaddelnLinkClick(true);
                }}
              >
                Jetzt Anmelden
              </a>
              {isAnpaddelnActive && (
                <div className={`anpaddelnOverlayed`}>
                  <div className="anpaddelnOverlayed--header">
                    <h4>Anmeldung Anpaddeln 2025</h4>
                    <span>
                      <MdClose
                        onClick={(e) => {
                          e.preventDefault();
                          handleAnpaddelnLinkClick(false);
                        }}
                      />
                    </span>
                  </div>
                  <Anpaddeln_Form />
                </div>
              )}
            </div>
          )}
        <Teaser />
        <About />
        <Training />
        {/* <PhotosEvents /> */}
        <PhotosEvents_New />
        <Contact />
      </main>
      <Footer />
      <Imprint />
    </>
  );
}

export default App;
